<template>
  <div class="youth-attendance" v-if="hasPerm('youth.view_seanceinscription')">
    <div class="youth-attendance-table" :id="'youth-attendance-table-' + id">
      <b-row>
        <b-col cols="3" class="no-print">
          <seances-list-filter
            @changed="onListFilterChanged($event)"
            @loaded="onListFilterLoaded($event)"
            stacked
            multi
            :id="id"
            :init-homes="initialYouthHomes"
            :init-types="initialSeanceTypes"
            :init-periods="initialPeriods"
          >
          </seances-list-filter>
          <div class="filter-bar">
            <b-row>
              <b-col>
                <b-form-group
                  label="Afficher par"
                  label-for="displayBy"
                  description=""
                >
                  <b-form-select
                    :id="'display-by' + id"
                    v-model="selectedDisplayBy"
                    @change="loadData()"
                  >
                    <b-form-select-option :value="elt" v-for="elt in displayBys" :key="elt.id">
                      {{ elt.name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Grouper par"
                  label-for="groupBy"
                  description=""
                >
                  <b-form-select
                    :id="'group-by' + id"
                    v-model="selectedGroupBy"
                    @change="loadData()"
                  >
                    <b-form-select-option :value="elt" v-for="elt in groupBys" :key="elt.id">
                      {{ elt.name }}
                    </b-form-select-option>
                  </b-form-select>
                  <div v-if="showForceCity">
                    <b-form-checkbox id="forceCity" v-model="forceCity">
                      Ville de facturation
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="filter-bar-optional">
            <div class="small-filter-title">Filtres optionnels</div>
            <b-form-group
              label="Voir le nombre"
              :label-for="'showBy' + id"
              description=""
            >
              <b-form-select
                :id="'showBy' + id"
                v-model="showBy"
                @change="loadData()"
              >
                <b-form-select-option :value="elt" v-for="elt in showByChoices" :key="elt.id">
                  {{ elt.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-select
              :id="'school' + id"
              v-model="selectedSchool"
              @change="loadData()"
              v-if="schools.length > 1"
            >
              <b-form-select-option :value="elt" v-for="elt in schools" :key="elt.id">
                {{ elt.name }}
              </b-form-select-option>
            </b-form-select>
            <date-frame-selector @change="onDateFrameChanged">
            </date-frame-selector>
            <b-form-group>
              <b-form-checkbox :id="'morning' + id" v-model="morning" :disabled="noMomentOnly">
                Matin
              </b-form-checkbox>
              <b-form-checkbox :id="'lunch' + id" v-model="lunch" :disabled="noMomentOnly">
                Repas
              </b-form-checkbox>
              <b-form-checkbox :id="'afternoon' + id" v-model="afternoon" :disabled="noMomentOnly">
                Après-midi
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :id="'includeCancellation' + id" v-model="includeCancellation">
                Inclure les annulations
              </b-form-checkbox>
              <b-form-checkbox :id="'includeAbsences' + id" v-model="includeAbsences">
                Inclure les absences
              </b-form-checkbox>
              <b-form-checkbox :id="'includeWaiting' + id" v-model="includeWaiting">
                Inclure les en attente
              </b-form-checkbox>
              <b-form-checkbox :id="'onlyWaiting' + id" v-model="onlyWaiting" v-if="includeWaiting">
                Seulement les en attente
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-select
                :id="'includeExcursions' + id"
                v-model="includeExcursions"
                @change="loadData()"
              >
                <b-form-select-option :value="elt.id" v-for="elt in includeExcursionsChoices" :key="elt.id">
                  {{ elt.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <b-form-select
                :id="'noMoment' + id"
                v-model="noMoment"
                @change="loadData()"
              >
                <b-form-select-option :value="elt" v-for="elt in noMomentChoices" :key="elt.id">
                  {{ elt.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Code séance"
              :label-for="'seanceCode' + id"
              description="Entrez le code pour filtrer les séances. Séparez les codes par des virgules"
            >
              <div class="text-right small" style="margin-top: -25px">
                <a href @click.prevent="noop()">
                  <i class="fa fa-refresh"></i> MAJ</a>
              </div>
              <b-form-input
                :id="'seanceCode' + id"
                v-model="seanceCodeFilter"
                @change="loadData()"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              label="Nom d'atelier"
              :label-for="'workshops' + id"
              description="Entrez les noms d'atelier séparés par des virgules"
            >
              <div class="text-right small" style="margin-top: -25px">
                <a href @click.prevent="noop()">
                  <i class="fa fa-refresh"></i> MAJ</a>
              </div>
              <b-form-input
                :id="'workshops' + id"
                v-model="workshopsFilter"
                @change="loadData()"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox :id="'entitiesList' + id" v-model="entitiesList">
                Contacter les familles
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <loading-gif :loading-name="loadingName"></loading-gif>
          <always-top @done="onAlwaysTopRecalc()" :recalc="recalcAlwaysTop"></always-top>
          <div v-if="!isLoading(loadingName) && isValid">
            <div class="empty-text" v-if="days.length === 0">
              Aucune donnée pour cette période
            </div>
            <div v-if="selectedGroupBy && selectedGroupBy.id === 12" class="custom-range-holder no-print">
              <div><b>Tranches de QF</b></div>
              <custom-range-input id="family-level" v-model="familyLevels"></custom-range-input>
            </div>
            <div v-if="selectedGroupBy && selectedGroupBy.id === 10" class="custom-range-holder no-print">
              <div><b>Groupes d'âge</b></div>
              <custom-range-input id="age-group" v-model="ageGroups"></custom-range-input>
            </div>
            <table v-if="days.length" class="full-table table-bordered table-striped">
              <tr>
                <th></th>
                <th
                  v-for="choice of groupByValues"
                  :key="choice.id"
                  class="text-right value-col header-col"
                  :title="choice.name"
                  v-show="isVisibleColum(choice)"
                >
                  <span v-if="groupBy">{{ choice.name | defaultValue('&nbsp;') }}</span>
                  <span v-else>Tous</span>
                </th>
                <th class="text-right" v-if="groupBy">Total</th>
              </tr>
              <tr v-if="totals && entitiesList">
                <th class="">
                  Contacter les familles
                </th>
                <th
                  v-for="choice of groupByValues"
                  :key="choice.id"
                   class="contact"
                  :title="choice.name"
                  v-show="isVisibleColum(choice)"
                >
                  <span v-if="totals.getEntities(choice).length">
                    <a href @click.prevent="contactChoiceEntities(choice)">
                      <i class="fa fa-envelope"></i>
                    </a>
                  </span>
                </th>
                <th class="contact" v-if="groupBy">
                  <span v-if="totals.allEntities().length">
                    <a href @click.prevent="contactAllEntities()">
                      <i class="fa fa-envelope"></i>
                    </a>
                  </span>
                </th>
              </tr>
              <tr v-for="elt of days" :key="elt.getLabel()">
                <th class="">
                  {{ elt.getLabel() }}
                </th>
                <td
                  v-for="choice of groupByValues"
                  :key="choice.id"
                  class="number value-col"
                  :title="choice.name"
                  v-show="isVisibleColum(choice)"
                >
                  {{ elt.getValue(choice) }}
                </td>
                <th class="number" v-if="groupBy">
                  {{ elt.total() }}
                </th>
              </tr>
              <tr v-if="totals">
                <th class="">
                  Nombre total {{ showBy.name }}
                </th>
                <th
                  v-for="choice of groupByValues"
                  :key="choice.id"
                  class="number"
                  :title="choice.name"
                  v-show="isVisibleColum(choice)"
                >
                  {{ totals.getValue(choice) }}
                </th>
                <th class="number" :title="'Somme des colonnes: ' + totals.total()" v-if="groupBy">
                  {{ totalIndividuals }}
                </th>
              </tr>
              <tr>
                <th class="">Somme</th>
                <td
                  v-for="choice of groupByValues"
                  :key="choice.id"
                  class="number value-col"
                  :title="choice.name"
                  v-show="isVisibleColum(choice)"
                >
                  {{ sumGroup(choice) }}
                </td>
                <th class="number" v-if="groupBy">
                  {{ sumSums() }}
                </th>
              </tr>
              <tr>
                <th class="">
                  Minimum {{ showBy.name }}
                </th>
                <td
                  v-for="choice of groupByValues"
                  :key="choice.id"
                  class="number value-col"
                  :title="choice.name"
                  v-show="isVisibleColum(choice)"
                >
                  {{ minGroup(choice) }}
                </td>
                <th class="number" v-if="groupBy">
                  {{ minTotal() }}
                </th>
              </tr>
              <tr>
                <th class="">
                  Maximum {{ showBy.name }}
                </th>
                <td
                  v-for="choice of groupByValues"
                  :key="choice.id"
                  class="number value-col"
                  :title="choice.name"
                  v-show="isVisibleColum(choice)"
                >
                  {{ maxGroup(choice) }}
                </td>
                <th class="number" v-if="groupBy">
                  {{ maxTotal() }}
                </th>
              </tr>
              <tr>
                <th class="">
                  Moyenne {{ showBy.name }}
                </th>
                <td
                  v-for="choice of groupByValues"
                  :key="choice.id"
                  class="number value-col"
                  :title="choice.name"
                  v-show="isVisibleColum(choice)"
                >
                  {{ avgGroup(choice) }}
                </td>
                <th class="number" v-if="groupBy">
                  {{ avgTotal() }}
                </th>
              </tr>
              <tr>
                <th class="">
                  Médiane {{ showBy.name }}
                </th>
                <td
                  v-for="choice of groupByValues"
                  :key="choice.id"
                  class="number value-col"
                  :title="choice.name"
                  v-show="isVisibleColum(choice)"
                >
                  {{ medianGroup(choice) }}
                </td>
                <th class="number" v-if="groupBy">
                  {{ medianTotal() }}
                </th>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import AlwaysTop from '@/components/Layout/AlwaysTop'
import SeancesListFilter from '@/components/Seances/Config/SeancesListFilter'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import CustomRangeInput from '@/components/Controls/CustomRangeInput.vue'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import store from '@/store'
import { makeChoice } from '@/types/base'
import { dateToString } from '@/filters/texts'
import { makeAttendanceItem } from '@/types/stats'
import { makeSchool } from '@/types/schools'
import { BackendApi } from '@/utils/http'
import { areSameArrays } from '@/utils/arrays'
import { sum, min, max, avg, median } from '@/utils/math'
import { compareNumbers } from '@/utils/sorting'

export default {
  name: 'youth-attendance-table',
  mixins: [BackendMixin],
  components: {
    AlwaysTop,
    CustomRangeInput,
    LoadingGif,
    SeancesListFilter,
    DateFrameSelector,
  },
  props: {
    id: String,
  },
  data() {
    return {
      days: [],
      groupBy: '',
      groupByValues: [],
      youthHomes: [],
      seanceTypes: [],
      periods: [],
      selectedDisplayBy: null,
      selectedGroupBy: null,
      groupBys: [
        makeChoice({ id: 0, name: 'Ne pas grouper', }),
        makeChoice({ id: 10, name: 'Groupe d\'age', }),
        makeChoice({ id: 11, name: 'Mixité', }),
        makeChoice({ id: 12, name: 'Quotient familial', }),
        makeChoice({ id: 13, name: 'Niveau scolaire', })
      ],
      showByChoices: [
        makeChoice({ id: 1, name: 'd\'enfants', }),
        makeChoice({ id: 2, name: 'de familles', }),
        makeChoice({ id: 3, name: 'd\'inscriptions', })
      ],
      noMomentChoices: [
        makeChoice({ id: 0, name: 'Inclure les garderies', }),
        makeChoice({ id: 1, name: 'Exclure les garderies', }),
        makeChoice({ id: 2, name: 'Seulement les garderies', })
      ],
      includeExcursionsChoices: [
        makeChoice({ id: 0, name: 'Exclure les séjours', }),
        makeChoice({ id: 1, name: 'Inclure les séjours', }),
        makeChoice({ id: 2, name: 'Seulement les séjours', })
      ],
      familyLevels: [],
      ageGroups: [],
      selectedSchool: null,
      schools: [],
      totals: null,
      totalIndividuals: 0,
      startDate: null,
      endDate: null,
      showBy: null,
      morning: false,
      lunch: false,
      afternoon: false,
      noMoment: null,
      includeCancellation: false,
      includeAbsences: false,
      includeWaiting: false,
      onlyWaiting: false,
      entitiesList: false,
      includeExcursions: 0,
      seanceCodeFilter: '',
      workshopsFilter: '',
      recalcAlwaysTop: false,
      forceCity: false,
      locationChoices: [],
      initialYouthHomes: [],
      initialPeriods: [],
      initialSeanceTypes: [],
    }
  },
  computed: {
    isValid() {
      return (
        this.youthHomes.length && this.seanceTypes.length && this.periods.length &&
        this.selectedDisplayBy && this.selectedGroupBy
      )
    },
    showForceCity() {
      if (store.getters.showForceCity && this.selectedGroupBy) {
        return this.locationChoices.map(elt => elt.id).indexOf(this.selectedGroupBy.id) >= 0
      }
      return false
    },
    displayBys() {
      const choices = [
        makeChoice({ id: 1, name: 'Journée', }),
        makeChoice({ id: 2, name: 'Séance', })
      ]
      if (!this.noMomentOnly) {
        choices.push(makeChoice({ id: 3, name: 'Moments de la journée', }))
      }
      return choices
    },
    noMomentOnly() {
      return this.noMoment && (this.noMoment.id === 2)
    },
    loadingName() {
      return 'youth-attendance-' + this.id
    },
    subTitle() {
      let texts = []
      if (this.youthHome) {
        texts.push(this.youthHome.name)
      }
      if (this.seanceType) {
        texts.push(this.seanceType.name)
      }
      if (this.selectedSchool && this.selectedSchool.id) {
        texts.push(this.selectedSchool.name)
      }
      texts = texts.concat(this.periods.map(elt => elt.name))
      return texts.join(' - ')
    },
  },
  mounted() {
    this.init()
  },
  watch: {
    id: function() {},
    familyLevels: function(newValue, oldValue) {
      if (!areSameArrays(newValue, oldValue)) {
        this.loadData()
      }
    },
    ageGroups: function(newValue, oldValue) {
      if (!areSameArrays(newValue, oldValue)) {
        this.loadData()
      }
    },
    morning: function(newValue, oldValue) {
      this.onChange()
    },
    lunch: function(newValue, oldValue) {
      this.onChange()
    },
    afternoon: function(newValue, oldValue) {
      this.onChange()
    },
    includeCancellation: function(newValue, oldValue) {
      this.onChange()
    },
    includeWaiting: function(newValue, oldValue) {
      this.onChange()
    },
    onlyWaiting: function(newValue, oldValue) {
      this.onChange()
    },
    includeAbsences: function(newValue, oldValue) {
      this.onChange()
    },
    forceCity: function(newValue, oldValue) {
      this.onChange()
    },
    noMomentOnly: function(newValue, oldValue) {
      if (
        newValue &&
        ((this.selectedDisplayBy === null) || (this.selectedDisplayBy.id === 3)) &&
        (this.displayBys.length > 0)
      ) {
        this.selectedDisplayBy = this.displayBys[0]
      }
    },
    entitiesList: function() {
      if (this.entitiesList) {
        this.loadData()
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.disabled = true
      this.recalcAlwaysTop = false
      this.startLoading(this.loadingName)
      await this.loadStats()
      this.endLoading(this.loadingName)
      this.disabled = false
      const that = this
      this.$nextTick(
        function() {
          that.recalcAlwaysTop = true
        }
      )
    },
    noop() {
      // no operation :-)
    },
    async init() {
      await this.loadSchools()
      await this.loadFamilyLevels()
      await this.loadAgeGroups()
      await this.loadLocationChoices()
      this.dataToValues()
      if (this.showBy === null) {
        this.showBy = this.showByChoices[0]
      }
      if (this.selectedDisplayBy === null) {
        this.selectedDisplayBy = this.displayBys[0]
      }
      if (this.noMoment === null) {
        this.noMoment = this.noMomentChoices[0]
      }
    },
    async loadLocationChoices() {
      const backendApi = new BackendApi('get', '/stats/api/location-type-choices/')
      try {
        let resp = await backendApi.callApi()
        const locationChoices = resp.data.map(elt => makeChoice(elt))
        this.locationChoices = locationChoices
        this.groupBys = locationChoices.concat(this.groupBys).sort(
          (elt1, elt2) => compareNumbers(elt1.id, elt2.id)
        )
        this.selectedGroupBy = this.groupBys[0]
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadFamilyLevels() {
      const backendApi = new BackendApi('get', '/stats/api/family-level-range/')
      const defaultLevels = [800, 1200, 1600]
      try {
        const resp = await backendApi.callApi()
        if (resp.data.length) {
          this.familyLevels = resp.data.map(elt => elt.level)
        } else {
          this.familyLevels = defaultLevels
        }
      } catch (err) {
        this.familyLevels = defaultLevels
        await this.addError(this.getErrorText(err))
      }
    },
    async loadAgeGroups() {
      const backendApi = new BackendApi('get', '/stats/api/age-groups/')
      const defaultGroups = [3, 6, 11]
      try {
        const resp = await backendApi.callApi()
        if (resp.data.length) {
          this.ageGroups = resp.data.map(elt => elt.start_age)
        } else {
          this.ageGroups = defaultGroups
        }
      } catch (err) {
        this.ageGroups = defaultGroups
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSchools() {
      const backendApi = new BackendApi('get', '/api/schools/schools/')
      try {
        const resp = await backendApi.callApi()
        this.schools = [
          makeSchool({ id: 0, name: 'Toutes les écoles', })
        ].concat(resp.data.map(elt => makeSchool(elt)))
        this.selectedSchool = this.schools[0]
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getData() {
      let data = {
        'youth_homes': this.youthHomes.map(elt => elt.id),
        'seance_types': this.seanceTypes.map(elt => elt.id),
        'periods': this.periods.map(elt => elt.id),
        'display_by': this.selectedDisplayBy.id,
        'group_by': this.selectedGroupBy.id,
        'start_date': this.startDate ? dateToString(this.startDate, 'YYYY-MM-DD') : null,
        'end_date': this.endDate ? dateToString(this.endDate, 'YYYY-MM-DD') : null,
        'family_levels': this.familyLevels,
        'age_groups': this.ageGroups,
        'show_by': this.showBy.id,
        'morning': this.morning,
        'lunch': this.lunch,
        'afternoon': this.afternoon,
        'include_cancellation': this.includeCancellation,
        'include_waiting': this.includeWaiting,
        'only_waiting': this.includeWaiting && this.onlyWaiting,
        'include_absences': this.includeAbsences,
        'no_moment': this.noMoment ? this.noMoment.id : 0,
        'entities_list': this.entitiesList,
        'include_excursions': this.includeExcursions,
        'seance_code': this.seanceCodeFilter.replace(',', '|'),
        'workshops': this.workshopsFilter.replace(',', '|'),
      }
      if (this.selectedSchool) {
        data.school = this.selectedSchool.id
      }
      if (this.showForceCity && this.forceCity) {
        data['show_forced_city'] = true
      }
      return data
    },
    dataToQueryArgs(data) {
      this.$router.replace({ query: data, })
    },
    routeArgToBoolean(arg) {
      const value = this.$route.query[arg]
      return value === 'true'
    },
    routeArgToChoice(arg, choices) {
      const value = +(this.$route.query[arg] || '0')
      return choices.find(elt => elt.id === value) || null
    },
    routeArgToChoiceId(arg) {
      return +(this.$route.query[arg] || '0')
    },
    routeArgToString(arg) {
      return this.$route.query[arg] || ''
    },
    routeArgToIds(arg) {
      const value = this.$route.query[arg]
      if (!value) {
        return []
      } else if (typeof value === 'string') {
        return [+value]
      } else {
        return value.map(elt => +elt)
      }
    },
    dataToValues() {
      this.initialYouthHomes = this.routeArgToIds('youth_homes')
      this.initialSeanceTypes = this.routeArgToIds('seance_types')
      this.initialPeriods = this.routeArgToIds('periods')
      this.startDate = this.routeArgToString('start_date') || null
      this.endDate = this.routeArgToString('end_date') || null
      this.familyLevels = this.routeArgToIds('family_levels')
      this.ageGroups = this.routeArgToIds('age_groups')
      this.selectedDisplayBy = this.routeArgToChoice('display_by', this.displayBys)
      this.selectedGroupBy = this.routeArgToChoice('group_by', this.groupBys)
      this.showBy = this.routeArgToChoice('show_by', this.showByChoices)
      this.morning = this.routeArgToBoolean('morning')
      this.lunch = this.routeArgToBoolean('lunch')
      this.afternoon = this.routeArgToBoolean('afternoon')
      this.includeCancellation = this.routeArgToBoolean('include_cancellation')
      this.includeWaiting = this.routeArgToBoolean('include_waiting')
      this.onlyWaiting = this.routeArgToBoolean('only_waiting')
      this.includeAbsences = this.routeArgToBoolean('include_absences')
      this.entitiesList = this.routeArgToBoolean('entities_list')
      this.noMoment = this.routeArgToChoice('no_moment', this.noMomentChoices)
      this.includeExcursions = this.routeArgToChoiceId('include_excursions')
      this.seanceCodeFilter = this.routeArgToString('seance_code').replace('|', ',')
      this.workshops = this.routeArgToString('workshops').replace('|', ',')
    },
    async loadStats() {
      if (this.isValid) {
        this.groupBy = ''
        this.groupByValues = []
        this.days = []
        let url = '/stats/api/youth-attendance/'
        const backendApi = new BackendApi('post', url)
        const data = this.getData()
        this.dataToQueryArgs(data)
        try {
          const resp = await backendApi.callApi(data)
          this.groupBy = resp.data.group_by
          this.groupByValues = resp.data.group_by_values.map(
            elt => makeChoice(elt)
          )
          this.buildDays(resp.data.items)
          this.buildTotals(resp.data.totals)
          this.buildEntities(resp.data.entities)
          this.totalIndividuals = resp.data['total_individuals']
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onListFilterChanged(evt) {
      if (evt.id === this.id) {
        this.youthHomes = evt.youthHomes
        this.seanceTypes = evt.seanceTypes
        this.periods = evt.periods
        this.loadData()
      }
    },
    onChange() {
      this.loadData()
    },
    onAlwaysTopRecalc() {
      this.recalcAlwaysTop = false
    },
    onListFilterLoaded(evt) {
    },
    getChoiceValues(choice) {
      return this.days.map(elt => elt.getValue(choice))
    },
    sumGroup(choice) {
      return sum(this.getChoiceValues(choice))
    },
    minGroup(choice) {
      return min(this.getChoiceValues(choice))
    },
    maxGroup(choice) {
      return max(this.getChoiceValues(choice))
    },
    avgGroup(choice) {
      return avg(this.getChoiceValues(choice))
    },
    medianGroup(choice) {
      return median(this.getChoiceValues(choice))
    },
    isVisibleColum(choice) {
      return this.sumGroup(choice) > 0
    },
    getHeaderColspan() {
      return this.groupByValues.filter(elt => this.isVisibleColum(elt)).length + 2
    },
    totalValues() {
      return this.days.map(elt => elt.total())
    },
    sumTotal() {
      return sum(this.totalValues())
    },
    sumSums() {
      return sum(this.groupByValues.map(choice => this.sumGroup(choice)))
    },
    minTotal() {
      return min(this.totalValues())
    },
    maxTotal() {
      return max(this.totalValues())
    },
    avgTotal() {
      return avg(this.totalValues())
    },
    medianTotal() {
      return median(this.totalValues())
    },
    buildDays(jsonItems) {
      const daysMap = new Map()
      const groupByValuesMap = new Map()
      for (const groupByValue of this.groupByValues) {
        groupByValuesMap.set(groupByValue.id, groupByValue)
      }
      for (const item of jsonItems) {
        let dayKey = item['seance__date']
        if (this.selectedDisplayBy.id === 2) {
          dayKey += '++' + item['seance__name']
        }
        if (this.selectedDisplayBy.id === 3) {
          dayKey += '//' + item['order']
        }
        let dayItem
        if (daysMap.has(dayKey)) {
          dayItem = daysMap.get(dayKey)
        } else {
          dayItem = makeAttendanceItem(item)
        }
        let choice = groupByValuesMap.get(item[this.groupBy]) || null
        if (choice === null) {
          const hasAllChoice = (this.groupByValues.filter(elt => elt.id === 0).length > 0)
          if (!hasAllChoice) {
            this.groupByValues = [makeChoice({})].concat(this.groupByValues)
          }
          choice = this.groupByValues[0]
        }
        const value = item['attendance']
        dayItem.addSubItem(choice, value)
        daysMap.set(dayKey, dayItem)
      }
      this.days = Array.from(daysMap.values())
    },
    buildTotals(jsonItems) {
      let hasAllChoice = false
      const totalItem = makeAttendanceItem(null, true)
      const groupByValuesMap = new Map()
      for (const groupByValue of this.groupByValues) {
        groupByValuesMap.set(groupByValue.id, groupByValue)
      }
      for (const item of jsonItems) {
        let choice = groupByValuesMap.get(item[this.groupBy]) || null
        if (choice === null) {
          choice = this.groupByValues[0]
        }
        const value = item['attendance']
        totalItem.addSubItem(choice, value)
      }
      this.totals = totalItem
    },
    buildEntities(jsonItems) {
      const groupByValuesMap = new Map()
      for (const groupByValue of this.groupByValues) {
        groupByValuesMap.set(groupByValue.id, groupByValue)
      }
      for (const key in jsonItems) {
        const entities = jsonItems[key]
        let choice = groupByValuesMap.get(+key) || null
        if (choice === null) {
          choice = this.groupByValues[0]
        }
        this.totals.addEntities(choice, entities)
      }
    },
    onDateFrameChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loadData()
    },
    contactEntities(entities) {
      router.push(
        {
          name: 'contact-entities',
          query: { ids: entities.map(elt => '' + elt).join('-'), },
        }
      )
    },
    contactChoiceEntities(choice) {
      const entities = this.totals.getEntities(choice)
      this.contactEntities(entities)
    },
    contactAllEntities() {
      const entities = this.totals.allEntities()
      this.contactEntities(entities)
    },
  },
}
</script>

<style scoped lang="less">
.youth-attendance-table {

  table.full-table {
    width: 100%;
  }

  table.full-table td, table.full-table th {
    padding: 3px 3px;
    font-size: 12px;
  }

  table.full-table th {
    background: #ddd;
    color: #222;
  }

  table.full-table tr:nth-child(odd) th {
    background: #ccc;
    color: #222;
  }

  table.full-table td.value-col, table.full-table th.value-col {
    max-width: 40px;
    overflow: hidden;
  }

  table.full-table th.header-col {
    font-size: 9px;
  }

  .sub-title {
    margin-bottom: 10px;
    font-weight: bold;
  }

  .number {
    text-align: right;
  }

  .small-filter-title {
    font-size: 12px;
    margin-bottom: 5px;
  }
  th.contact {
    text-align: right;
  }
}
.dark-mode {
  .youth-attendance-table {
    table.full-table th {
      background: #383838 !important;
      color: #fff !important;
    }

    table.full-table tr:nth-child(odd) th {
      background: #585858 !important;
      color: #fff !important;
    }
  }
}
</style>
